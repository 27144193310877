<template>
  <el-container>
    <el-main>
      <!-- <div>
        <el-button
          type="primary"
          size="small"
        >开单收银</el-button>
      </div> -->
      <el-form class="el-form-search" label-width="100px">
        <el-form-item label="订单编号：">
          <el-input size="small" placeholder="请输入订单编号" v-model="form.pay_sn"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：">
          <el-input size="small" placeholder="请输入商品名称" v-model="form.goods_name"></el-input>
        </el-form-item>
        <el-form-item label="支付方式：">
          <el-select size="small" placeholder="请选择" v-model="form.payment_code">
            <el-option v-for="item in pay_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作时间：">
          <el-date-picker size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" v-model="form.timeVal"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <!-- <el-button size="small" @click="exportOrder">导出</el-button> -->
          <el-button type="text" @click="resetOrder">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-tabs type="card" v-model="form.status" @tab-click="search" v-if="stateArr.length > 0">
        <el-tab-pane :name="item.status + ''" v-for="item in stateArr" :key="item.status">
          <span slot="label">{{ item.title }}（{{ item.count }}）</span>
        </el-tab-pane>
      </el-tabs>
      <table style="width: 100%">
        <thead class="main_header">
          <tr class="table_header">
            <td class="th_item item2">商品信息</td>
            <td class="th_item">单价/数量</td>
            <td class="th_item item3">支付</td>
            <td class="th_item">总价</td>
            <td class="th_item">折扣</td>
            <td class="th_item">应收</td>
            <td class="th_item item3">会员信息</td>
            <td class="th_item item3">操作时间</td>
            <td class="th_item">订单状态</td>
          </tr>
        </thead>
        <No v-if="!list.length" />
        <tbody class="orders">
          <div class="order_item" v-for="item in list" :key="item.id">
            <tr class="item_info">
              <span class="item_pay_sn">订单编号：{{ item.pay_sn }}</span>
            </tr>
            <template v-for="(g_item, g_index) in item.order_goods">
              <tr v-if="g_index == 0 || item.isopen" :key="g_index" class="item_goods">
                <td class="g_item2">
                  <img :src="g_item.goods_img" class="g_item_image" />
                  <div class="g_item_info">
                    <div class="g_item_name g_item_text">{{ g_item.goods_title }}</div>
                    <div class="g_item_spec g_item_text">{{ g_item.goods_spec }}</div>
                  </div>
                </td>

                <td class="g_item">
                  <div class="g_item_text">￥ {{ g_item.goods_price }}</div>
                  <div class="g_item_text">× {{ g_item.goods_num }}</div>
                </td>

                <td class="g_item3">
                  <template v-if="g_index == 0">
                    <div class="g_item_text">{{ payType(item.payment_code) }}：￥{{ item.amount }}</div>
                    <div class="g_item_text">会员余额抵扣：￥{{ item.pd_amount }}</div>
                  </template>
                </td>

                <td class="g_item">
                  <template v-if="g_index == 0">
                    <div class="g_item_text">￥ {{ item.goods_amount }}</div>
                  </template>
                </td>

                <td class="g_item">
                  <template v-if="g_index == 0">
                    <div class="g_item_text">￥ {{ item.discount_reduction_amount }}</div>
                  </template>
                </td>

                <td class="g_item">
                  <template v-if="g_index == 0">
                    <div class="g_item_text">￥ {{ (item.goods_amount - item.discount_reduction_amount - item.reduction_amount).toFixed(2) }}</div>
                  </template>
                </td>

                <td class="g_item3">
                  <template v-if="g_index == 0">
                    <div class="g_item_text">{{ item.member_name }}</div>
                    <div class="g_item_text">{{ item.member_mobile }}</div>
                  </template>
                </td>

                <td class="g_item3">
                  <template v-if="g_index == 0">
                    <div class="g_item_text">{{ getDateformat(item.create_time) }}</div>
                  </template>
                </td>

                <td class="g_item">
                  <template v-if="g_index == 0">
                    <el-link :type="item.state == 10 ? 'danger' : 'primary'" :underline="false">{{ getState(item.state) }}</el-link>
                  </template>
                </td>
              </tr>
            </template>
            <el-link type="primary" :underline="false" @click="item.isopen = !item.isopen" class="isopen" v-if="item.order_goods.length > 1">{{ item.isopen ? '收起' : '展开' }}</el-link>
          </div>
        </tbody>
      </table>
      <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </el-container>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import config from '@/util/config';
import { mapState } from 'vuex';
let print_con = localStorage.getItem('printCon') || ['商品图片', '商品单价', '商品总价', '优惠金额', '快递运费', '商家备注'];
if (typeof print_con == 'string') {
  print_con = print_con.split(',');
}
export default {
  components: {
    Paging,
  },
  data() {
    return {
      form: {
        page: 1,
        rows: 10,
        pay_sn: null,
        goods_name: null,
        payment_code: 0,
        timeVal: null,
        status: '-1',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pay_type: [
        {
          val: 0,
          label: '全部',
        },
        {
          val: 10,
          label: '微信支付',
        },
        {
          val: 20,
          label: '支付宝支付',
        },
        {
          val: 40,
          label: '余额支付',
        },
      ],
      stateArr: [],
      list: [],
      total: 0,

      showSetPrinting: !1,
      checkAll: !1,
      checkedCities: print_con,
      cities: print_con,
      isIndeterminate: !0,

      showCancelOrder: !1,
      showMessage: !1,
      currentSelectOrderInfo: {},

      cancel_reason_id: '', //取消原因id
      state_remark: '', //取消说明
    };
  },
  created() {
    this.getOrderList();
    this.getOrderStatus();
  },
  computed: {
    ...mapState({
      cancelList: (state) => state.order.cancelList,
    }),
  },
  watch: {
    showCancelOrder(val) {
      if (val && !this.cancelList.length) this.$store.dispatch('order/getCancelList', { type: 4 });
    },
  },
  methods: {
    exportOrder() {
      this.$axios
        .post(this.$api.cashier.CashierOrder, {
          is_export: 1,
        })
        .then((res) => {
          if (res.code == 0) {
            let path = config.baseurl + '/' + res.result;
            let a = document.createElement('a');
            a.href = path;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    search() {
      this.form.page = 1;
      this.getOrderStatus();
      this.getOrderList();
    },
    getOrderList() {
      this.$axios
        .post(this.$api.cashier.CashierOrder, {
          page: this.form.page,
          rows: this.form.rows,
          pay_sn: this.form.pay_sn,
          goods_name: this.form.goods_name,
          payment_code: this.form.payment_code,
          start_time: this.form.timeVal ? this.form.timeVal[0] : null,
          end_time: this.form.timeVal ? this.form.timeVal[1] : null,
          status: this.form.status,
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list;
            list.forEach((item) => {
              item.isopen = true;
            });
            this.list = list;
            this.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
      } else {
        this.form.page = val;
      }
      // 重新获取数据
      this.getOrderList();
    },
    // 处理时间
    getDateformat(val) {
      return getDateformat(val);
    },
    // 获取支付方式
    payType(val) {
      let str = '实付';
      if (val && val != 40) {
        this.pay_type.forEach((item) => {
          if (item.val === val * 1) {
            console.log(item.val, val);
            str = item.label;
          }
        });
      }
      return str;
    },
    // 获取订单状态
    getState(state) {
      let str = null;
      this.stateArr.forEach((item) => {
        if (item.status == state) {
          str = item.title;
        }
      });
      return str;
    },
    getOrderStatus() {
      this.$axios
        .post(this.$api.cashier.CashierOrderStatus, {
          pay_sn: this.form.pay_sn,
          goods_name: this.form.goods_name,
          payment_code: this.form.payment_code,
          start_time: this.form.timeVal ? this.form.timeVal[0] : null,
          end_time: this.form.timeVal ? this.form.timeVal[1] : null,
        })
        .then((res) => {
          if (res.code == 0) {
            this.stateArr = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetOrder() {
      this.form = {
        page: 1,
        rows: 10,
        pay_sn: null,
        goods_name: null,
        payment_code: 0,
        timeVal: null,
        status: '-1',
      };
      this.getOrderStatus();
      this.getOrderList();
    },
    //打印
    print(row) {
      let routeData = this.$router.resolve('/print/' + row.id);
      window.open(routeData.href, '_blank');
    },
    // 订单详情
    orderDetail(id) {
      this.$router.push('/order/orderInfo/' + id + '/' + this.form.page + '/' + this.form.rows);
    },

    handleCheckAllChange(val) {
      this.checkedCities = val ? print_con : [];
      this.isIndeterminate = !1;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    //设置打印内容
    setPrintCon() {
      this.showSetPrinting = !1;
      localStorage.setItem('printCon', this.checkedCities);
    },

    //取消订单
    cancelOrder(row) {
      this.currentSelectOrderInfo = row;
      this.state_remark = '';
      this.showCancelOrder = !0;
    },
    comfirmCancelOrder() {
      if (!this.cancel_reason_id)
        return this.$message({
          message: '请选择取消原因',
          type: 'warning',
        });
      if (!this.state_remark)
        return this.$message({
          message: '请填写取消说明',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.order.cancel, {
          id: this.currentSelectOrderInfo.id,
          state_remark: this.state_remark,
          cancel_reason_id: this.cancel_reason_id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.showCancelOrder = !1;
            this.$message({
              message: '订单已取消',
              type: 'success',
            });
            this.getOrderList();
            this.getOrderStatus();
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    //设置备注信息
    setSystemMessage(row) {
      this.currentSelectOrderInfo = row;
      this.showMessage = !0;
    },
    comfirmSetSystemMessage() {
      this.$axios
        .post(this.$api.order.setSystemMessage, {
          id: this.currentSelectOrderInfo.id,
          system_message: this.currentSelectOrderInfo.system_message,
        })
        .then((res) => {
          if (res.code == 0) {
            this.showMessage = !1;
            this.$message({
              message: '备注成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scope>
.el-main {
  background: #fff;
  .main_header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
    .table_header {
      height: 50px;
      background: #f8f9fa;
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 10px;
      .th_item {
        text-align: center;
        flex: 1;
      }
      .item2 {
        flex: 2.3;
      }
      .item3 {
        flex: 1.3;
      }
    }
  }
  .orders {
    border: 1px solid #e4e7ed;
    .item_info {
      height: 40px;
      background: #f8f9fa;
      font-size: 14px;
      line-height: 40px;
      border-bottom: 1px solid #e4e7ed;
      padding: 0 20px;
      display: flex;
      align-items: center;
      .item_pay_sn {
        margin-right: 50px;
      }
    }
    .item_goods {
      display: flex;
      padding: 13px 0;
      align-items: center;
      .g_item {
        text-align: center;
        flex: 1;
      }
      .g_item3 {
        text-align: center;
        flex: 1.3;
      }
      .g_item2 {
        flex: 2.3;
        display: flex;
        .g_item_image {
          width: 60px;
          height: 60px;
          margin-right: 14px;
          margin-left: 20px;
        }
        .g_item_info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 20px;
          .g_item_name {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
          }
        }
      }
      .g_item_text {
        font-size: 14px;
        color: #333;
        line-height: 20px;
      }
    }
    .isopen {
      margin-left: 94px;
      margin-bottom: 20px;
    }
  }
}
.goods_info {
  width: 300px;
  flex: none;
  display: flex;
  .el-image {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .goods_title {
    text-align: left;
    flex: 1;
    p:nth-child(1) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 5px;
    }
  }
}
</style>
